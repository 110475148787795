import styled from 'styled-components';

export const PreviewRoot = styled.div`
  width: ${p => p?.$width}px;
  min-width: ${p => p?.$width}px;
  height: ${p => p?.$height}px;
  max-height: ${p => p?.$height}px;
  min-height: ${p => p?.$height}px;
  margin: 8px 8px 8px 0;
  display: flex;
  justify-content: center;
  //overflow: hidden;
`;

export const ImgSide = styled.img`
  width: ${p => p?.$width}px;
  height: ${p => p?.$height}px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalWrapper = styled.div`
  .content {
    max-height: unset;
  }
`;
