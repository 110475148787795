import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Konva from 'konva';
import {Stage, Layer, Group, Image} from 'react-konva';
import {ModalWrapper} from '../modalWrapper';
import * as S from './styled';
import {getPreviewDataUrl} from '../../helpers/imageHelper';
import {cmToPixels, pixelsToCm} from '../../helpers/unitHelper';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {CanvasRuler} from '../canvasRuler/CanvasRuler';

const withRuler = true;

export const PatternPreview = props => {
  const {
    popupRef,
    onClose,
    settings,
    scale: scaleProp,
    canvasPreviewRef,
    previewWithRulerRef,
    previewSize,
  } = props;
  const {t} = useTranslation(['PatternPreview']);
  const {width: screenWidth, height: screenHeight} = useWindowDimensions();
  const buttonsContainer = null;
  const previewBlobUrl = getPreviewDataUrl(
    canvasPreviewRef?.current,
    previewWithRulerRef?.current,
    settings,
    scaleProp,
    1,
  );
  const [canvasImage, setCanvasImage] = useState(null);

  const previewAspectRatio = previewSize.width / previewSize.height;
  const titleHeight = 40;
  const bottomLabelHeight = 12;
  const canvasMargin = 20;
  const maxScreenWidth = screenWidth * 0.8;
  const maxScreenHeight = screenHeight * 0.8 - titleHeight;
  let containerHeight = 0;
  let containerWidth = 0;
  if (
    screenWidth > screenHeight &&
    maxScreenHeight * previewAspectRatio < screenWidth
  ) {
    containerHeight = maxScreenHeight;
    containerWidth = containerHeight * previewAspectRatio;
  } else {
    containerWidth = maxScreenWidth;
    containerHeight = containerWidth / previewAspectRatio;
  }
  const imageWidthCm = pixelsToCm(settings?.width, settings?.dpi);
  const imageHeightCm = pixelsToCm(settings?.height, settings?.dpi);
  // const virtualPixel = (containerWidth - canvasMargin*2) / previewSize.width;
  // const imageWidth = imageWidthCm * virtualPixel;
  // const imageHeight = imageHeightCm * virtualPixel;
  const previewWidthPx = cmToPixels(previewSize.width, 96);
  const previewHeightPx = cmToPixels(previewSize.height, 96);
  const scaleX = (containerWidth - canvasMargin) / previewWidthPx;
  const scaleY = (containerHeight - canvasMargin * 2) / previewHeightPx;
  const scale = Math.min(scaleX, scaleY);
  const rulerWidth = scale ? canvasMargin / scale : 0;
  const rulerLineWeight = 1 / scale;
  const canvasWidth =
    previewWidthPx * scale + (withRuler ? rulerWidth * scale : 0);
  const canvasHeight =
    previewHeightPx * scale + (withRuler ? rulerWidth * scale : 0);
  const canvasImagesAreaWidth =
    canvasWidth - (withRuler ? rulerWidth * scale : 0);
  const virtualPixel = canvasImagesAreaWidth / previewSize.width;
  const imageWidth = imageWidthCm * virtualPixel;
  const imageHeight = imageHeightCm * virtualPixel;

  const rows = parseInt(containerHeight / imageHeight) + 1;
  const columns = parseInt(containerWidth / imageWidth) + 1;

  useEffect(() => {
    if (previewBlobUrl) {
      const image = new window.Image();
      image.src = previewBlobUrl;
      image.onload = () => {
        setCanvasImage(image);
      };
    }
  }, [previewBlobUrl]);

  const imagesContainer = Array(rows)
    .fill(0)
    .map((_, i) =>
      Array(columns)
        .fill(0)
        .map((_, j) => (
          <Image
            key={`${i}-${j}`}
            width={imageWidth / scale}
            height={imageHeight / scale}
            image={canvasImage}
            x={(j * imageWidth) / scale}
            y={(i * imageHeight) / scale}
          />
        )),
    );

  const rulerContainer = (
    <CanvasRuler
      width={previewWidthPx}
      height={previewHeightPx}
      scale={scale}
      rulerWidth={rulerWidth}
      lineWeight={rulerLineWeight}
      sizeUnit={settings?.sizeUnit}
    />
  );

  const canvasContainer = (
    <Stage
      width={canvasWidth + rulerLineWeight}
      height={canvasHeight + rulerLineWeight + bottomLabelHeight}>
      <Layer>
        <Group scaleX={scale} scaleY={scale} draggable={false}>
          {rulerContainer}
          <Group
            x={rulerWidth}
            y={rulerWidth}
            clipWidth={canvasWidth / scale - rulerWidth}
            clipHeight={canvasHeight / scale - rulerWidth}>
            {imageWidth ? imagesContainer : null}
          </Group>
        </Group>
      </Layer>
    </Stage>
  );

  return (
    <S.ModalWrapper>
      <ModalWrapper
        popupRef={popupRef}
        onCancelClick={() => onClose()}
        title={t('Pattern Preview')}
        withCross
        isScrolled={false}
        buttonsContainer={buttonsContainer}>
        <S.PreviewRoot $width={containerWidth} $height={containerHeight}>
          {canvasContainer}
        </S.PreviewRoot>
      </ModalWrapper>
    </S.ModalWrapper>
  );
};
