import React, {useEffect, useRef, useState} from 'react';
import {Layer, Rect} from 'react-konva';

const PADDING = 5;
const THICKNESS = 10;

export const CanvasScroll = props => {
  const {
    stageWidth,
    stageHeight,
    scale,
    stageRef,
    previewWithScaleRef,
    getCanvasPosX,
    getCanvasPosY,
    canvas,
  } = props;
  const [wheelState, setWheelState] = useState({dx: 0, dy: 0});
  const verticalBarRef = useRef();
  const horizontalBarRef = useRef();
  const isShowVerticalBar = canvas?.height > stageHeight;
  const isShowHorizontalBar = canvas?.width > stageWidth;
  const maxCanvasSide = Math.max(canvas.width, canvas.height);
  const verticalBarHeight =
    canvas.height > stageHeight
      ? stageHeight * 2 - canvas.height
      : stageHeight - PADDING * 2;
  const horizontalBarWidth =
    canvas.width > stageWidth
      ? stageWidth * 2 - canvas.width
      : stageWidth - PADDING * 2;

  const getAvailableHeight = () => {
    return stageHeight - PADDING * 2 - verticalBarHeight;
  };

  const getAvailableWidth = () => {
    return stageWidth - PADDING * 2 - horizontalBarWidth;
  };

  const onVerticalBarMove = v => {
    previewWithScaleRef.current.setPosition({
      x: previewWithScaleRef.current.x(),
      y: getCanvasPosY() - ((v - 0.5) * maxCanvasSide) / 2,
    });
  };

  const onHorizontalBarMove = v => {
    previewWithScaleRef.current.setPosition({
      x: getCanvasPosX() - ((v - 0.5) * maxCanvasSide) / 2,
      y: previewWithScaleRef.current.y(),
    });
  };

  const setVerticalBarPosition = v => {
    if (verticalBarRef.current) {
      verticalBarRef.current.setPosition({
        x: verticalBarRef.current.x(),
        y: v * getAvailableHeight() + PADDING,
      });
    }
  };

  const setHorizontalBarPosition = v => {
    if (horizontalBarRef.current) {
      horizontalBarRef.current.setPosition({
        x: v * getAvailableWidth() + PADDING,
        y: horizontalBarRef.current.y(),
      });
    }
  };

  const onMouseWheel = (dx, dy, canvasPos) => {
    //const maxCanvasSide = Math.max(canvas.width, canvas.height);
    const minX = getCanvasPosX() - maxCanvasSide / 4;
    const maxX = getCanvasPosX() + maxCanvasSide / 4;
    const x = Math.max(minX, Math.min(canvasPos.x - dx, maxX));
    const minY = getCanvasPosY() - maxCanvasSide / 4;
    const maxY = getCanvasPosY() + maxCanvasSide / 4;
    const y = Math.max(minY, Math.min(canvasPos.y - dy, maxY));
    if (isShowHorizontalBar && isShowVerticalBar) {
      previewWithScaleRef.current.setPosition({x, y});
    } else if (isShowVerticalBar) {
      previewWithScaleRef.current.setPosition({
        x: previewWithScaleRef.current.x(),
        y,
      });
    } else if (isShowHorizontalBar) {
      previewWithScaleRef.current.setPosition({
        x,
        y: previewWithScaleRef.current.y(),
      });
    }
  };

  function dragVerticalBar(pos) {
    pos.x = stageWidth - PADDING - 10;
    pos.y = Math.max(
      Math.min(pos.y, stageHeight - this.height() - PADDING),
      PADDING,
    );
    return pos;
  }

  function dragHorizontalBar(pos) {
    pos.x = Math.max(
      Math.min(pos.x, stageWidth - this.width() - PADDING),
      PADDING,
    );
    pos.y = stageHeight - PADDING - 10;
    return pos;
  }

  useEffect(() => {
    if (verticalBarRef.current) {
      verticalBarRef.current.on('dragmove', () => {
        // delta in %
        const delta =
          (verticalBarRef.current.y() - PADDING) / getAvailableHeight();
        onVerticalBarMove(delta);
      });
    }
    if (horizontalBarRef.current) {
      horizontalBarRef.current.on('dragmove', () => {
        // delta in %
        const delta =
          (horizontalBarRef.current.x() - PADDING) / getAvailableWidth();
        onHorizontalBarMove(delta);
      });
    }
  }, [verticalBarRef, horizontalBarRef, scale]);

  useEffect(() => {
    if (stageRef?.current) {
      stageRef.current.on('wheel', e => {
        // prevent parent scrolling
        e.evt.preventDefault();
        const dx = e.evt.deltaX;
        const dy = e.evt.deltaY;
        setWheelState({dx, dy});
      });
    }
  }, [stageRef, scale, canvas?.width, canvas?.height]);

  useEffect(() => {
    const {dx, dy} = wheelState;
    const canvasPos = previewWithScaleRef?.current?.getPosition();
    onMouseWheel(dx, dy, canvasPos);
    const deltaX = ((getCanvasPosX() - canvasPos.x) / maxCanvasSide) * 2 + 0.5;
    const deltaY = ((getCanvasPosY() - canvasPos.y) / maxCanvasSide) * 2 + 0.5;
    setHorizontalBarPosition(deltaX);
    setVerticalBarPosition(deltaY);
  }, [wheelState, scale]);

  useEffect(() => {
    if (verticalBarRef.current) {
      setVerticalBarPosition(0.5);
      onVerticalBarMove(0.5);
    }
    if (horizontalBarRef.current) {
      setHorizontalBarPosition(0.5);
      onHorizontalBarMove(0.5);
    }
  }, [scale]);

  const verticalBar = (
    <Rect
      ref={verticalBarRef}
      width={THICKNESS}
      height={verticalBarHeight}
      fill={'grey'}
      opacity={0.8}
      x={stageWidth - PADDING - THICKNESS}
      y={PADDING}
      draggable={true}
      dragBoundFunc={dragVerticalBar}
      visible={isShowVerticalBar}
    />
  );

  const horizontalBar = (
    <Rect
      ref={horizontalBarRef}
      width={horizontalBarWidth}
      height={THICKNESS}
      fill={'grey'}
      opacity={0.8}
      x={PADDING}
      y={stageHeight - PADDING - THICKNESS}
      draggable={true}
      dragBoundFunc={dragHorizontalBar}
      visible={isShowHorizontalBar}
    />
  );
  return (
    <Layer>
      {horizontalBar}
      {verticalBar}
    </Layer>
  );
};
