import opentype from 'opentype.js';
import {getRoundValue} from './positionHelper';
import fontNorthwell from '../assets/fonts/Northwell/NORTHWELL.OTF';

const assetFontsData = [
  {
    name: 'Northwell',
    url: fontNorthwell,
  },
];

export const getFontUrl = (fontName, customFonts = []) => {
  return [...assetFontsData, ...customFonts].find(i => i?.name === fontName)
    ?.url;
};

export const getFontDetailsByFile = async file => {
  const promise = new Promise((resolve, reject) => {
    opentype.load(file, function (err, font) {
      if (err) {
        reject(err);
      }
      resolve(font);
    });
  });
  return await promise;
};

export const getLineHeightByFontDetails = font => {
  const ascender = font.ascender;
  const descender = font.descender;
  const unitsPerEm = font.unitsPerEm;

  // Calculate line height in font units
  const lineHeight = ascender - descender;
  // Normalize line height to em units (pixels if scaled to font size)
  return getRoundValue(lineHeight / unitsPerEm);
};
