import React from 'react';
import Konva from 'konva';
import {Group} from 'react-konva';
import {getFixedParams} from '../../../helpers/renderHelper';

export const getRepeatedObjectFixedLogic = props => {
  const {o: i, width, height} = props;
  let repeatList = [];
  let x = 0;
  let y = 0;
  let line = 0;
  const o = new Konva[i?.className](i?.params);
  const ow = o?.getSize().width * (i?.params?.scaleX || 1);
  const oh = o?.getSize().height * (i?.params?.scaleY || 1);
  const w = i?.params?.rotation === 90 || i?.params?.rotation === 270 ? oh : ow;
  const h = i?.params?.rotation === 90 || i?.params?.rotation === 270 ? ow : oh;
  const rotateSizeScale = 1.3;
  const maxSide = Math.max(width, height);
  if (i?.params?.repeat === 'repeat-x') {
    while (x < maxSide * rotateSizeScale) {
      repeatList.push(
        <i.className
          key={`key=${i?.key},x=${x},y=${y}`}
          {...i?.params}
          x={x}
          y={y}
        />,
      );
      x += w + (i?.params?.repeatXMargins || 0);
    }
  }
  if (i?.params?.repeat === 'repeat-y') {
    while (y < maxSide * rotateSizeScale) {
      repeatList.push(
        <i.className
          key={`key=${i?.key},x=${x},y=${y}`}
          {...i?.params}
          x={x}
          y={y}
        />,
      );
      y += h + (i?.params?.repeatYMargins || 0);
    }
  }
  if (
    i?.params?.repeat === 'repeat' ||
    i?.params?.repeat === 'repeat-wave' ||
    i?.params?.repeat === 'repeat-wave-vertical'
  ) {
    while (y < maxSide * rotateSizeScale) {
      let row = 0;
      while (x < maxSide * rotateSizeScale) {
        repeatList.push(
          <i.className
            key={`key=${i?.key},x=${x},y=${y}`}
            {...getFixedParams(i?.params)}
            x={x}
            y={
              i?.params?.repeat === 'repeat-wave-vertical' && row % 2
                ? y + h / 2
                : y
            }
          />,
        );
        row += 1;
        x += w + (i?.params?.repeatXMargins || 0);
      }
      line += 1;
      x =
        i?.params?.repeat === 'repeat-wave' && line % 2
          ? (w + (i?.params?.repeatXMargins || 0)) / 2
          : 0;
      y += h + (i?.params?.repeatYMargins || 0);
    }
  }
  return (
    <Group
      ref={ref => {
        if (ref && i?.params) {
          //syncParams(ref, i?.params);
          //cacheObject(ref, i);
        }
        //objectsRefs.current[i?.id] = ref;
      }}
      rotation={i?.params?.repeatRotation || 0}
      x={i?.params?.repeatOffsetX}
      y={i?.params?.repeatOffsetY}
      key={i?.key}>
      {repeatList}
    </Group>
  );
};

export const getRepeatedObjectOldLogic = props => {
  const {o: i, width, height} = props;
  let repeatList = [];
  let x = i?.params?.repeatOffsetX || 0;
  let y = i?.params?.repeatOffsetY || 0;
  let line = 0;
  const o = new Konva[i?.className](i?.params);
  const ow = o?.getSize().width * (i?.params?.scaleX || 1);
  const oh = o?.getSize().height * (i?.params?.scaleY || 1);
  const w = i?.params?.rotation === 90 || i?.params?.rotation === 270 ? oh : ow;
  const h = i?.params?.rotation === 90 || i?.params?.rotation === 270 ? ow : oh;
  const rotateSizeScale = 1.3;
  const maxSide = Math.max(width, height);
  if (i?.params?.repeat === 'repeat-x') {
    while (x < maxSide * rotateSizeScale) {
      repeatList.push(
        <i.className
          key={`key=${i?.key},x=${x},y=${y}`}
          {...i?.params}
          x={x}
          y={y}
        />,
      );
      x += w + (i?.params?.repeatXMargins || 0);
    }
  }
  if (i?.params?.repeat === 'repeat-y') {
    while (y < maxSide * rotateSizeScale) {
      repeatList.push(
        <i.className
          key={`key=${i?.key},x=${x},y=${y}`}
          {...i?.params}
          x={x}
          y={y}
        />,
      );
      y += h + (i?.params?.repeatYMargins || 0);
    }
  }
  if (
    i?.params?.repeat === 'repeat' ||
    i?.params?.repeat === 'repeat-wave' ||
    i?.params?.repeat === 'repeat-wave-vertical'
  ) {
    while (y < maxSide * rotateSizeScale) {
      let row = 0;
      while (x < maxSide * rotateSizeScale) {
        repeatList.push(
          <i.className
            key={`key=${i?.key},x=${x},y=${y}`}
            {...getFixedParams(i?.params)}
            x={x}
            y={
              i?.params?.repeat === 'repeat-wave-vertical' && row % 2
                ? y + h / 2
                : y
            }
          />,
        );
        row += 1;
        x += w + (i?.params?.repeatXMargins || 0);
      }
      line += 1;
      x =
        (i?.params?.repeat === 'repeat-wave' && line % 2
          ? (w + (i?.params?.repeatXMargins || 0)) / 2
          : 0) + (i?.params?.repeatOffsetX || 0);
      y += h + (i?.params?.repeatYMargins || 0);
    }
  }
  return (
    <Group
      ref={ref => {
        if (ref && i?.params) {
          //syncParams(ref, i?.params);
          //cacheObject(ref, i);
        }
        //objectsRefs.current[i?.id] = ref;
      }}
      rotation={i?.params?.repeatRotation || 0}
      key={i?.key}>
      {repeatList}
    </Group>
  );
};

export const getRepeatedObject = props => {
  if (props?.o?.params?.repeatLogic === 'fixed') {
    return getRepeatedObjectFixedLogic(props);
  } else {
    return getRepeatedObjectOldLogic(props);
  }
};
