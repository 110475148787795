import styled from 'styled-components';

export const Header = styled.header`
  width: 100%;
  max-height: 70px;
  height: 70px;
  ${props => props.theme.screenSize.xl} {
    max-height: 50px;
    height: 50px;
  }
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const BackBlock = styled.div`
  height: 100%;
  border-right: 1px solid #e6e6e6;
  text-decoration: none;
  max-width: ${p => p.theme.sideMenuWidth};
  min-width: ${p => p.theme.sideMenuWidth};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;

  svg {
    height: 20px;
    width: 10px;
    color: #5e5d5d;
  }
`;

export const HeaderTitle = styled.h1`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  padding-left: 30px;
  font-family: 'Roboto';
`;

export const TitleWithLayerSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const LayerSelector = styled.div`
  //margin-right: 20px;
`;

export const CloseBlock = styled.div`
  height: 100%;
  text-decoration: none;
  max-width: ${p => p.theme.sideMenuWidth};
  min-width: ${p => p.theme.sideMenuWidth};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;

  svg {
    color: #5e5d5d;
  }
`;
