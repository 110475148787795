import React, {useEffect, useRef} from 'react';
import {Stage, Layer, Group, Rect, Transformer} from 'react-konva';
import {cacheObject, classNames, objectTypes} from '../../helpers/canvasHelper';
import {getFixedParams} from '../../helpers/renderHelper';
import {CanvasRuler} from '../canvasRuler/CanvasRuler';
import {CanvasScroll} from '../canvasScroll/CanvasScroll';
import {getRoundValue} from '../../helpers/positionHelper';
import {initImageWithCache} from '../../helpers/imageHelper';
import {CanvasUnitSelector} from '../canvasUnitSelector/CanvasUnitSelector';
import {isFirefox} from '../../helpers/browserHelper';
import {CanvasZoomControl} from '../canvasZoomControl/CanvasZoomControl';
import {getRepeatedObject} from './helpers/renderHelper';

const CanvasView = props => {
  const {
    canvasPreviewRef,
    previewWithScaleRef,
    width,
    height,
    objects,
    scale,
    objectsRefs,
    withRuler = true,
    rulerWidth,
    visibleArea,
    editMode,
    selectedIds,
    showTransform,
    onObjectClick,
    setShapeChange: setShapeChangeProp,
    canvasRotation,
    dpi = 96,
    onZoomInClick,
    onZoomOutClick,
    transformMode = 'Scale',
  } = props;

  const stageWidth = visibleArea?.clientWidth;
  const stageHeight = visibleArea?.clientHeight;
  const bgColor = '#f6f6f6';
  const bottomLabelHeight = 12;
  const canvasWidth = width * scale + (withRuler ? rulerWidth * scale : 0);
  const canvasHeight =
    height * scale + (withRuler ? rulerWidth * scale : 0) + bottomLabelHeight;
  const reservedFillWidth = visibleArea.clientWidth * 5;
  const lineHeight = 1 / scale;
  const stageRef = useRef(null);
  const transformRef = useRef(null);
  const objectChangesRef = useRef(null);
  const setShapeChange = v => {
    objectChangesRef.current = v;
  };

  const getCanvasPosX = () => {
    const defX = (visibleArea?.clientWidth - canvasWidth) / 2;
    switch (canvasRotation) {
      case 90:
        return defX + canvasHeight - (canvasHeight - canvasWidth) / 2;
      case 180:
        return defX + canvasWidth;
      case 270:
        return defX - (canvasHeight - canvasWidth) / 2;
      default:
        return defX;
    }
  };

  const getCanvasPosY = () => {
    const defY = (visibleArea?.clientHeight - canvasHeight) / 2;
    switch (canvasRotation) {
      case 90:
        return defY + (canvasHeight - canvasWidth) / 2;
      case 180:
        return defY + canvasHeight;
      case 270:
        return defY + canvasHeight - (canvasHeight - canvasWidth) / 2;
      default:
        return defY;
    }
  };

  useEffect(() => {
    if (selectedIds?.[0]) {
      stageRef.current.container().style.cursor = 'pointer';
    } else {
      stageRef.current.container().style.cursor = 'default';
    }
  }, [selectedIds]);

  // useEffect(() => {
  //   if (previewWithScaleRef?.current) {
  //     previewWithScaleRef.current.setPosition({
  //       x: getCanvasPosX(),
  //       y: getCanvasPosY(),
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (objects?.length) {
      objects.forEach(o => {
        const oRef = objectsRefs.current?.[o?.id];
        if (stageRef?.current && oRef) {
          if (o.type !== objectTypes.background && o?.personalization?.length) {
            if (
              !oRef?.eventListeners?.mouseenter?.length &&
              !oRef?.eventListeners?.mouseleave?.length
            ) {
              // oRef.on('mouseenter', function () {
              //   stageRef.current.container().style.cursor = 'pointer';
              // });
              // oRef.on('mouseleave', function () {
              //   stageRef.current.container().style.cursor = 'default';
              // });
            }
            if (isFirefox()) {
              //oRef.cache();
            }
          }
        }
      });
    }
    if (transformRef.current && selectedIds?.length) {
      const allowedToTransformIds = selectedIds.filter(id => {
        const o = objects.find(i => i?.id === id);
        return (
          o?.locked !== true &&
          o?.params?.visible !== false &&
          o?.type !== objectTypes.background
        );
      });
      const allowedObjectsRefs = allowedToTransformIds.map(
        i =>
          /*objectsRefs.current?.[i + 'group'] || */ objectsRefs.current?.[i],
      );
      const objectsRefsNotEmpties = allowedObjectsRefs.filter(i => i);
      transformRef.current.nodes(objectsRefsNotEmpties);
      transformRef.current.getLayer().batchDraw();
    }
  }, [objects, selectedIds]);

  const onDragEnd = (e, id) => {
    setShapeChangeProp({
      id,
      action: 'onDragEnd',
      params: {
        x: e.target.x(),
        y: e.target.y(),
      },
    });
  };

  const onTransform = (e, o) => {
    if (
      o?.className === classNames.textPath ||
      o?.params?.fillPatternImage?.src ||
      selectedIds?.length > 1 ||
      transformMode === 'Scale'
    ) {
      setShapeChange({
        id: o?.id,
        action: 'onTransform',
        params: {
          rotation: getRoundValue(e.target.attrs.rotation),
          scaleX: getRoundValue(e.target.attrs.scaleX),
          scaleY: getRoundValue(e.target.attrs.scaleY),
          x: e.target.x(),
          y: e.target.y(),
        },
      });
    } else if (o?.className === classNames.text) {
      setShapeChange({
        id: o?.id,
        action: 'onTransform',
        params: {
          rotation: getRoundValue(e.target.attrs.rotation),
          scaleX: o?.params?.scaleX || 1,
          scaleY: o?.params?.scaleY || 1,
          ...(o?.params?.width === undefined && o?.params?.height === undefined
            ? {
                fontSize: getRoundValue(
                  (o?.params.fontSize / (o?.params?.scaleY || 1)) *
                    e.target.attrs.scaleY,
                ),
              }
            : {}),
          ...(o?.params?.width !== undefined
            ? {
                width: getRoundValue(
                  (o?.params.width / (o?.params?.scaleX || 1)) *
                    e.target.attrs.scaleX,
                ),
              }
            : {}),
          ...(o?.params.height !== undefined
            ? {
                height: getRoundValue(
                  (o?.params.height / (o?.params?.scaleY || 1)) *
                    e.target.attrs.scaleY,
                ),
              }
            : {}),
        },
      });
    } else {
      setShapeChange({
        id: o?.id,
        action: 'onTransform',
        params: {
          rotation: getRoundValue(e.target.attrs.rotation),
          ...(o?.params?.width !== undefined
            ? {
                width: getRoundValue(
                  (o?.params.width / (o?.params?.scaleX || 1)) *
                    e.target.attrs.scaleX,
                ),
              }
            : {}),
          ...(o?.params.height !== undefined
            ? {
                height: getRoundValue(
                  (o?.params.height / (o?.params?.scaleY || 1)) *
                    e.target.attrs.scaleY,
                ),
              }
            : {}),
          ...(o?.params?.radius !== undefined
            ? {
                radius: getRoundValue(
                  (o?.params.radius / (o?.params?.scaleY || 1)) *
                    e.target.attrs.scaleY,
                ),
              }
            : {}),
        },
      });
    }
  };

  const onMouseAction = () => {
    if (objectChangesRef?.current) {
      setShapeChangeProp(objectChangesRef?.current);
      setShapeChange(null);
    }
  };

  const objectsListContainer = objects.map(i => {
    if (i?.params?.repeat) {
      return getRepeatedObject({
        o: i,
        width,
        height,
      });
    }

    const component = (
      <i.className
        ref={ref => {
          if (
            ref &&
            i?.params &&
            (i?.params?.image || i?.params?.fillPatternImage)
          ) {
            initImageWithCache(i, ref);
          } else if (ref && i?.params && i?.type !== objectTypes.text) {
            cacheObject(ref, i);
          }
          objectsRefs.current[i?.id] = ref;
        }}
        {...getFixedParams(i?.params)}
        key={i?.key}
        draggable={
          editMode &&
          i?.type !== objectTypes.background &&
          i?.personalization?.length /* && !i?.params?.background*/
        }
        onClick={() => {
          if (
            onObjectClick &&
            i.type !== objectTypes.background &&
            i?.personalization?.length
          ) {
            onObjectClick(i);
          } else {
            onObjectClick(null);
          }
        }}
        onDragEnd={e => onDragEnd(e, i?.id)}
        onTransform={e => onTransform(e, i)}
      />
    );

    const backgroundInTextComponent = (
      <Rect
        // ref={ref => {
        //   objectsRefs.current[i?.id + 'bg'] = ref;
        // }}
        {...i?.params}
        fill={i?.params?.background}
        width={i?.params?.width || objectsRefs.current[i?.id]?.getWidth()}
        height={i?.params?.height || objectsRefs.current[i?.id]?.getHeight()}
      />
    );

    return i?.params?.background ? (
      <Group
        key={i?.key}
        ref={ref => {
          objectsRefs.current[i?.id + 'group'] = ref;
        }}
        draggable={
          editMode &&
          i?.type !== objectTypes.background &&
          i?.personalization?.length
        }>
        {backgroundInTextComponent}
        {component}
      </Group>
    ) : (
      component
    );
  });

  const transform = selectedIds?.length ? (
    <Transformer
      ref={transformRef}
      boundBoxFunc={(oldBox, newBox) => {
        // limit resize
        if (newBox.width < 5 || newBox.height < 5) {
          return oldBox;
        }
        return newBox;
      }}
    />
  ) : null;

  const ruler = (
    <CanvasRuler
      width={width}
      height={height}
      scale={scale}
      rulerWidth={rulerWidth}
      lineWeight={lineHeight}
      clientWidth={visibleArea?.clientWidth}
      canvasWidth={canvasWidth}
      canvasHeight={canvasHeight}
      dpi={dpi}
    />
  );

  const canvasUnitSelector = (
    <CanvasUnitSelector x={visibleArea?.clientWidth - 100} y={15} />
  );

  const canvasZoomControl = (
    <CanvasZoomControl
      x={15}
      y={15}
      onZoomInClick={onZoomInClick}
      onZoomOutClick={onZoomOutClick}
    />
  );

  const canvasScroll = (
    <CanvasScroll
      stageWidth={stageWidth}
      stageHeight={stageHeight}
      scale={scale}
      stageRef={stageRef}
      previewWithScaleRef={previewWithScaleRef}
      getCanvasPosX={getCanvasPosX}
      getCanvasPosY={getCanvasPosY}
      canvas={{width: canvasWidth, height: canvasHeight}}
    />
  );

  return (
    <Stage
      ref={stageRef}
      width={stageWidth}
      height={stageHeight}
      onMouseOver={onMouseAction}
      onTouchEnd={onMouseAction}>
      <Layer>
        <Group
          ref={previewWithScaleRef}
          width={canvasWidth}
          height={canvasHeight}
          scaleX={scale}
          scaleY={scale}
          // x={getCanvasPosX()}
          // y={getCanvasPosY()}
          // clipX={0}
          // clipY={0}
          clipWidth={canvasWidth / scale}
          clipHeight={canvasHeight / scale}
          draggable={!editMode}
          rotation={canvasRotation}>
          <Group
            ref={canvasPreviewRef}
            x={withRuler ? rulerWidth : 0}
            y={withRuler ? rulerWidth : 0}
            clipX={0}
            clipY={0}
            clipWidth={width}
            clipHeight={height}>
            {objectsListContainer}
            {showTransform ? transform : null}
          </Group>
          {withRuler ? <Group>{ruler}</Group> : null}
        </Group>
        {canvasUnitSelector}
        {canvasZoomControl}
      </Layer>
      {canvasScroll}
    </Stage>
  );
};
export default CanvasView;
