import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faClose} from '@fortawesome/free-solid-svg-icons';
import * as S from './styled';
import {Dropdown} from '../../components';

export const Header = props => {
  const {name, templateLayers, selectedLayer, onChangeLayer, onBack, onClose} =
    props;

  const layerSelector = (
    <Dropdown
      data={templateLayers}
      onChange={v => onChangeLayer(v)}
      value={selectedLayer}
      getOptionLabel={v => v?.description || v?.name}
    />
  );

  return (
    <S.Header>
      <S.BackBlock onClick={onBack}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </S.BackBlock>
      <S.TitleWithLayerSelector>
        <S.HeaderTitle>{name || '-'}</S.HeaderTitle>
        <div style={{display: 'flex', alignItems: 'center'}}>
          {templateLayers?.length > 1 ? (
            <S.LayerSelector>{layerSelector}</S.LayerSelector>
          ) : null}
          <S.CloseBlock onClick={onClose}>
            <FontAwesomeIcon icon={faClose} />
          </S.CloseBlock>
        </div>
      </S.TitleWithLayerSelector>
    </S.Header>
  );
};
